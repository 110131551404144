import * as actionTypes from "../actionTypes/actionTypes";

export const addFavoriUtilisateur = partenaire => ({
    type: actionTypes.UTILISATEUR_ADD_FAVORI_SUCCESS,
    partenaire
});

export const getInformationsDeclarant = gencod => ({
    type: actionTypes.UTILISATEUR_GET_INFORMATIONS_DECLARANT,
    gencod
});

export const getInformationsDeclarantSuccess = (informations, roles, regroupements) => ({
    type: actionTypes.UTILISATEUR_GET_INFORMATIONS_DECLARANT_SUCCESS,
    informations,
    roles,
    regroupements
});

export const updatePreferenceSizePagination = (gencod, preferenceSizePagination) => ({
    type: actionTypes.UTILISATEUR_UPDATE_PREFERENCE_SIZE_PAGINATION,
    gencod,
    preferenceSizePagination
});

export const updateFlagBibliothequeEtrangere = isBibliothequeEtranger => ({
    type: actionTypes.UTILISATEUR_UPDATE_FLAG_BIBLIOTHEQUE_ETRANGERE,
    isBibliothequeEtranger
});

export const updatePreferenceSizePaginationSuccess = (gencod, preferenceSizePagination) => ({
    type: actionTypes.UTILISATEUR_UPDATE_PREFERENCE_SIZE_PAGINATION_SUCCESS,
    gencod,
    preferenceSizePagination
});

export const updateInfoPremiereconnexionSuccess = payload => ({
    type: actionTypes.UTILISATEUR_UPDATE_INFO_PREMIERE_CONNEXION_SUCCESS,
    payload
});

export const updateLocaleForUser = (gencod, locale) => ({
    type: actionTypes.UPDATE_LOCALE_FOR_USER,
    gencod,
    locale
});

export const updateGencod = gencod => ({
    type: actionTypes.UPDATE_GENCOD,
    gencod
});

export const updateUtilisateurLoading = loading => ({
    type: actionTypes.UTILISATEUR_UPDATE_LOADING,
    loading
});

export const updateFavorisList = () => ({
    type: actionTypes.UTILISATEUR_UPDATE_FAVORIS
});

export const updateFavorisListSuccess = favoris => ({
    type: actionTypes.UTILISATEUR_UPDATE_FAVORIS_SUCCESS,
    favoris
});

export const reloadFavorisBeforeMount = flag => ({
    type: actionTypes.RELOAD_FAVORIS_BEFORE_MOUNT,
    flag
});
