import { push } from "connected-react-router";
import { call, put, select } from "redux-saga/effects";
import {
    enregistrerWrapperDto,
    envoyerWrapperDto,
    modifierStatutDeclarationBibliotheque,
    modifierStatutDeclarationFournisseur,
    valideOneDeclartionBibliothequeEnEtat,
    valideOneDeclartionFournisseurEnEtat
} from "../../../services/declaration.service";
import { getListDeclarationsLigneByNumeroDeclaration } from "../../../services/integrationTableur.service";
import {
    calculateDdp,
    calculTtc,
    checkIsFournisseurConnected,
    checkProfilConnected,
    getComparator,
    getProfilPartenaire,
    isNotEmptyArray,
    isNullOrUndefined,
    prepareLigne,
    stableSort,
    updateEnteteWithTvaAndTtc,
    updateLignesWithTva
} from "../../../utils/utility";
import * as actions from "../../actions/index.action";
import {
    showNextIntegrationExcel
} from "../integrationTableur/recapitulatifDeclarations/integrationTableurRecapitulatifDeclaration.saga";
import { showNextMesDeclarations } from "../mesDeclarations/mesDeclarations.saga";
import { contactStrings, isNotEmpty } from "../../../utils/stringUtil";
import {
    selectCodePostalFournisseur, selectDeclarationDeclarantGencod,
    selectDeclarationLoading, selectDeclarationPartenaireGencod,
    selectMonProfilCompteUtilisateur,
    selectRootPath,
    selectState,
    selectUtilisateurGencod,
    selectUtilisateurRegroupements,
    selectUtilisateurRoles,
    selectValidEntetes,
    selectValidLignes
} from "../../../utils/selectors";
import { getTauxTvaForBibliotheque, getTauxTvaForFournisseur } from "../../../services/tva.service";
import {
    updateCodePostalFournisseur,
    updateDeclarationLignesAndEntetesAndCodePostalFournisseur
} from "../../actions/declaration.action";
import { rechercheInfoPartenaire } from "../rechercheGLN/rechercheGLN.saga";
import { getCell } from "../../../utils/testUtil";
import { PATH_DECLARATION } from "../../../routes/paths/paths.util";
import {
    ERREUR_ABANDONNER_DECLARATION, ERREUR_GET_DECLARATION_LIGNE_BY_NUMERO_DECLARATION,
    ERREUR_GET_TAUX_TVA_FOR_DECLARATION,
    ERREUR_SAUVEGARDER_DECLARATION,
    ERREUR_VALIDER_DECLARATION_EN_ETAT, LIBELLE_ERREUR_GLN
} from "../../../utils/libelleConstantes";
import { ROLES } from "../../../utils/constantes";

// eslint-disable-next-line no-unused-vars
function setEnteteToPost(state, { tva, dateModification, ...rest }) {
    return {
        entete: {
            ...rest,
            glnPartenaire: {
                ...rest.glnPartenaire,
                cellValue: state.declaration.partenaire.gencod
            },
            glnDeclarant: getCell(state.declaration.declarant.gencod)
        },
        type: state.declaration.typeDeclaration
    };
}

function setLigneToPost(state, ligne, index) {
    // Même si ils ne sont pas utilisés, on retire tous les attributs qui ne sont pas des objets représentant des cellules
    // eslint-disable-next-line no-unused-vars
    const { valide, numeroLigne, canBeMergedByEan, canBeMergedByTitleAndEditor, dateModification, validable, ...cellsData } = { ...ligne };
    return {
        ligne: cellsData,
        numeroLigne: index, // On est forcé de réécrire les numéros pour éviter les trous en cas de suppression de ligne
        valide,
        validable
    };
}

export function *applyServiceToDeclaration(service, index, applySortByDateModif) {
    const state = yield select(selectState);
    const declaration = state.declaration;
    const declarationFglo = state.utilisateur.informations.canMakeDeclarationGlobale;
    const declarationGlobaleFromStore = declaration.declaration.validEntetes.length === 1 && declaration.declaration.globale;
    const hasEntetes = isNotEmptyArray(declaration.declaration.validEntetes);
    const hasLignes = isNotEmptyArray(declaration.declaration.validLignes);
    const hasPartenaire = isNotEmpty(state.declaration.partenaire.gencod);
    const hasDeclarant = isNotEmpty(state.declaration.declarant.gencod);

    /*
     * Afin d'éviter des appels inutiles vers le back, on vérifie que la déclaration est complète, elle doit y avoir
     * - Un seul entête(pour une déclaration détaillée) ou plusieurs entêtes pour une globale
     * - un / plusieurs lignes pour une déclaration détaillée
     * - un partenaire bien rempli
     * - un déclarant bien rempli
     */
    if (hasEntetes && (declarationFglo || hasLignes || declarationGlobaleFromStore) && hasPartenaire && hasDeclarant) {
        let entetes = declaration.declaration.validEntetes.map(entete => setEnteteToPost(state, entete));
        if (hasLignes) {
            entetes = [
                {
                    ...entetes[0],
                    lignes: declaration.declaration.validLignes.map((ligne, i) => setLigneToPost(state, ligne, i + 1))
                }
            ];
        }

        const objectBody = {
            entetes
        };
        try {
            yield put(actions.setDeclarationLoading(true));
            const response = yield service(objectBody);
            yield put(actions.reinitPartenaire(false));
            if (declaration.enableToUpdateStore && response.data.data.wrapperDto) {
                yield put(actions.saveDeclarationsSuccess(response.data.data.wrapperDto, index, applySortByDateModif));
            }
        } catch (error) {
            yield put(actions.openDialogNoRedirect(ERREUR_SAUVEGARDER_DECLARATION, error.cause || error.message));
        }
        yield put(actions.updateEnableUpdateStore(true));
        yield put(actions.updateMdDialogDeclarationModifEnCours(false));
    }

    // Permet de débloquer la popup après une sauvegarde
    const loading = yield select(selectDeclarationLoading);
    if (loading) {
        yield put(actions.setDeclarationLoading(false));
    }
}

export function *postDeclaration(index = -1, applySortByDateModif = false) {
    yield call(applyServiceToDeclaration, enregistrerWrapperDto, index, applySortByDateModif);
}

export function *sendDeclaration(index = -1, applySortByDateModif = false) {
    const glnDeclarant = yield select(selectUtilisateurGencod);
    yield call(applyServiceToDeclaration, envoyerWrapperDto, index, applySortByDateModif);
    yield put(actions.checkIfEcartIsPresent(glnDeclarant));
}

export function *saveDeclarationSaga() {
    yield call(postDeclaration);
}

export function *updateEnteteAndLignesSaga({ tva }) {
    const entetesValides = yield select(selectValidEntetes);
    const lignesValides = yield select(selectValidLignes);

    const lignes = lignesValides.filter(ligne => ligne.valide === true);
    const newEntetes = entetesValides.map(entete => {
        const totalTtcFromTva = calculTtc(entete.totalHt.cellValue, tva);
        return {
            ...entete,
            totalTtc: {
                ...entete.totalTtc,
                cellValue: totalTtcFromTva
            }
        };
    });
    yield put(actions.updateDeclarationEntetes(newEntetes));

    if (lignes) {
        const lignesWithTva = updateLignesWithTva(lignes, tva);
        if (isNotEmpty(lignesWithTva)) {
            yield put(actions.updateDeclarationLignes(lignesWithTva));
        }
    }
    yield call(postDeclaration);
}

export function *updateAllEnteteAndSaveDeclarationSaga({ entetes }) {
    yield put(actions.updateDeclarationEntetes(entetes));
    yield call(postDeclaration);
}

export function *updateEnteteAndSaveDeclarationSaga({ index, entete, toSaveDeclaration }) {
    yield put(actions.setDeclarationLoading(true));
    yield put(actions.updateDeclarationEntete(index, entete));
    if (toSaveDeclaration) {
        yield call(postDeclaration, index);
    }
}

export function *updateStatutEntetesAndSendDeclaration(entetes, toBeAbandon, index = 0) {
    const state = yield select(selectState);
    const profil = checkProfilConnected(state.utilisateur.roles);
    const numeroDeclarationList = entetes.map(entete => entete.numeroDeclaration.cellValue);
    yield put(actions.updateDeclarationEnteteStatus(profil, numeroDeclarationList, toBeAbandon));
    const updatedEntetes = entetes.map(entete => ({
        ...entete,
        valide: {
            ...entete.valide,
            cellValue: (toBeAbandon ? "false" : "true")
        }
    }));
    if (entetes.length === 1) {
        yield put(actions.updateDeclarationEntete(index, updatedEntetes[0]));
    } else {
        yield put(actions.updateDeclarationEntetes({ ...updatedEntetes }));
    }
}

export function *cancelEntetesAndSendDeclaration({ entetes, index }) {
    yield call(updateStatutEntetesAndSendDeclaration, entetes, true, index);
}

export function *cancelEntetesWebAndSendDeclaration({ entetes, reinitDeclarant }) {
    if (entetes) {
        yield call(updateStatutEntetesAndSendDeclaration, entetes, true);
    }
    yield put(actions.reinitializeDeclarationData(reinitDeclarant));
}

export function *revalidEntetesAndSendDeclaration({ entetes, index }) {
    yield call(updateStatutEntetesAndSendDeclaration, entetes, false, index);
}

export function *updateLigneAndSaveDeclaration(action) {
    yield put(actions.setDeclarationLoading(true));
    yield put(actions.updateDeclarationLigne(action.index, action.ligne));
    yield call(postDeclaration, action.index, action.applySortByDateModif);
}

export function *cancelLigneAndSendDeclaration({ index, ligne }) {
    yield put(actions.cancelLigne(index, ligne));
    yield call(postDeclaration, index);
}

export function *deleteLigneAndSendDeclaration({ index, ligne }) {
    /*
     * Lors d'une suppression de ligne, on envoie la déclaration au back sans la ligne que l'on souhaite supprimer
     * Mais on a besoin de mettre à jour le store avec le retour du back pour l'entête (dont la somme des pphts et le montant de ddp ont changé) et les lignes
     * (qui ont été renumérotées lors de l'envoi du front pour réattribuer le numéro de la ligne supprimée)
     */
    yield put(actions.deleteLigne(index, ligne));
    yield call(postDeclaration);
}

export function *revalidLigneAndSendDeclaration({ index, ligne }) {
    yield put(actions.revalidLigne(index, ligne));
    yield call(postDeclaration, index);
}

export function *mergeLigneAndSendDeclaration({ index, indexDoublon, quantityToAdd }) {
    yield put(actions.mergeLigne(index, indexDoublon, quantityToAdd));
    yield call(postDeclaration, index);
}

export function *sendDeclarationSaga() {
    yield put(actions.updateEnableUpdateStore(false));
    yield call(sendDeclaration);
    yield put(actions.sentDeclaration());
}

export function *validateOneDeclarationEnEtatSaga(action, showNextDeclarationFunction) {
    yield put(actions.setDeclarationLoading(true));
    let response;
    try {
        if (action.profil === ROLES.BIBLIOTHEQUE) {
            response = yield valideOneDeclartionBibliothequeEnEtat(action.numeroDeclaration);
        } else {
            response = yield valideOneDeclartionFournisseurEnEtat(action.numeroDeclaration);
        }
        if (Object.entries(response.data.data).length > 0) {
            yield put(actions.saveDeclarationsSuccess(response.data.data.wrapperDto));
            yield put(actions.setDeclarationLoading(false));
            yield call(showNextDeclarationFunction);
        }
    } catch (error) {
        yield put(actions.setDeclarationLoading(false));
        yield put(actions.openDialogNoRedirect(ERREUR_VALIDER_DECLARATION_EN_ETAT, error.cause || error.message));
    }
}

export function *validateOneDeclarationEnEtatSagaIntegrationExcel(action) {
    yield call(validateOneDeclarationEnEtatSaga, action, showNextIntegrationExcel);
}

export function *validateOneDeclarationEnEtatSagaMesDeclarations(action) {
    yield call(validateOneDeclarationEnEtatSaga, action, showNextMesDeclarations);
}

export function *modifierStatutDeclarationEnteteSaga(action) {
    try {
        if (action.profil === ROLES.BIBLIOTHEQUE) {
            yield call(modifierStatutDeclarationBibliotheque, action.numeroDeclarationList, action.toBeAbandon);
        } else {
            yield call(modifierStatutDeclarationFournisseur, action.numeroDeclarationList, action.toBeAbandon);
        }
        yield put(actions.updateDeclarationEnteteStatusSuccess(action.numeroDeclarationList, action.toBeAbandon));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_ABANDONNER_DECLARATION, error.cause || error.message));
    }
}


export function *updateDeclarantAndPartenaireSaga({ declarant, partenaire }) {
    const raisonSocialeDeclarant = contactStrings(declarant.raisonSociale1, declarant.raisonSociale2);
    yield put(actions.updateDeclarantInfo(declarant.gencod, raisonSocialeDeclarant, declarant.ville, declarant.codePostal, false));
    if (partenaire) {
        yield put(actions.updatePartenaireInfo(partenaire.gencodPartenaire, partenaire.raisonSociale, partenaire.ville, partenaire.codePostal, false));
    }
}

export function *updateDeclarationSaga({ entete, globale, typeDeclaration }) {
    const state = yield select(selectState);
    const coordonneesUtilisateur = selectMonProfilCompteUtilisateur(state);
    const regroupements = selectUtilisateurRegroupements(state);
    const order = state.declaration.order;
    const glnFromDeclaration = entete.glnDeclarant.cellValue;

    /**
     *  Si le gln connecté est celui présent sur la déclaration, on cherche les infos dans l'objet informations,
     *   Sinon on cherche les infos sur l'objet regroupements
     */
    let declarant = coordonneesUtilisateur;
    if (coordonneesUtilisateur.gencod !== glnFromDeclaration) {
        const declarantRegroupe = regroupements.find(r => r.gencod === glnFromDeclaration);
        declarant = declarantRegroupe || declarant;
    }

    if (entete.ddp === undefined) {
        entete.ddp = calculateDdp(entete.livreHt.cellValue);
    }
    yield put(actions.updateDeclarationEntete(0, entete));
    yield put(actions.updateDeclarationGlobale(globale));
    yield put(actions.updateTypeDeclaration(typeDeclaration));

    const { favoris } = state.utilisateur;
    const partenaire = favoris.find(fav => fav.gencodPartenaire === entete.glnPartenaire.cellValue);
    if (partenaire === undefined) {
        try {
            yield call(rechercheInfoPartenaire, {
                profil: getProfilPartenaire(state.utilisateur.roles),
                filtre: {
                    gencod: entete.glnPartenaire.cellValue,
                    raisonSociale: "",
                    adresse: "",
                    codePostal: "",
                    ville: ""
                },
                // On ne déclanche pas la sauvegarde coté back
                save: false
            });
        } catch (error) {
            yield put(actions.openDialogNoRedirect(LIBELLE_ERREUR_GLN, error.message));
        }
    }
    // Maj partenaire et déclarant sans sauvegarde coté back
    yield call(updateDeclarantAndPartenaireSaga, { declarant, partenaire });

    if (!globale) {
        yield put(actions.setDeclarationLoading(true));
        try {
            const response = yield call(getListDeclarationsLigneByNumeroDeclaration, entete.numeroDeclaration.cellValue);
            let lignes = Object.values(response.data);
            lignes = lignes.map((item, index) => prepareLigne(item, index));
            const sortedLignes = stableSort(lignes, getComparator(order, "dateModification"));
            yield put(actions.updateDeclarationLignes(sortedLignes));
        } catch (error) {
            yield put(actions.openDialogNoRedirect(ERREUR_GET_DECLARATION_LIGNE_BY_NUMERO_DECLARATION, error.message));
        }

        yield put(actions.setDeclarationLoading(false));
    }
}

export function *redirectDeclaration({ typeDeclaration, id = "" }) {
    const pathRoot = yield select(selectRootPath);
    // Si on précise un id, alors on ajout à la fin de l'url, un slash et l'id
    const endUrl = id !== "" ? `/${id}` : "";
    yield put(actions.reinitializeDeclarationData());
    yield put(push(`${pathRoot}${PATH_DECLARATION}/${typeDeclaration}${endUrl}`));
}

/**
 * Lors de la mise à jour du déclarant dans la déclaration, le montant de la TVA est mise à jour
 * Le prix TTC de chaque entete est donc recalculé
 * @param codePostal Nouveau code postal fournisseur
 * @param gencod le gencod fournisseur utilisé pour calculer la TVA
 * @param majDeclaration Indique si une mise a jour de la tva et des ttc doit etre effectuée
 */
export function *updateDeclarantPartenaireSaga(codePostal, majDeclaration = true) {
    const roles = yield select(selectUtilisateurRoles);
    const gencodPartenaire = yield select(selectDeclarationPartenaireGencod);
    const gencodDeclarant = yield select(selectDeclarationDeclarantGencod);
    const codePostalOld = yield select(selectCodePostalFournisseur);
    const isFour = checkIsFournisseurConnected(roles);
    if (codePostalOld !== codePostal && majDeclaration) {
        const entetes = yield select(selectValidEntetes);
        const lignes = yield select(selectValidLignes);
        const entetesUpdated = [];
        // Intialiser les lignes modifées par les lignes originales
        let lignesUpdated = lignes;
        for (let i = 0; i < entetes.length; i++) {
            try {
                const response = yield isFour ? gencodPartenaire && call(getTauxTvaForFournisseur, gencodDeclarant, gencodPartenaire) : call(getTauxTvaForBibliotheque, gencodDeclarant);
                const newTva = response.data;
                let ttc = entetes[i].totalTtc.cellValue;
                if (newTva !== entetes[i].tva) {
                    ttc = calculTtc(entetes[i].totalHt.cellValue, newTva);
                    // Mise a jour des lignes avec le nouveau tva de l'entete
                    // eslint-disable-next-line max-depth
                    if (i === 0) {
                        lignesUpdated = updateLignesWithTva(lignes, newTva);
                    }
                }
                const newEntete = updateEnteteWithTvaAndTtc(entetes[i], newTva, ttc);
                entetesUpdated.push(newEntete);
            } catch (error) {
                yield put(actions.openDialogNoRedirect(ERREUR_GET_TAUX_TVA_FOR_DECLARATION, error.message));
            }
        }
        // Met a jour le store avec les lignes, entetes (contenants nouvelles tva) et code postal
        yield put(updateDeclarationLignesAndEntetesAndCodePostalFournisseur(lignesUpdated, entetesUpdated, codePostal));
    } else if (codePostalOld !== codePostal) {
        yield put(updateCodePostalFournisseur(codePostal));
    }
}

/**
 * Si le declarant est une bib, il faut mettre à jour le codePostalFournisseur
 * ainsi que la tva si besoin pour les entetes avec les infos du partenaire:
 */
export function *updatePartenaireSaga({ codePostalPartenaire, save }) {
    const roles = yield select(selectUtilisateurRoles);
    if (checkIsFournisseurConnected(roles)) {
        yield call(updateDeclarantPartenaireSaga, codePostalPartenaire, save);
    }
    if (save) {
        yield call(saveDeclarationSaga);
    }
}

/**
 * Si le declarant est le fournisseur, il faut mettre à jour le codePostalFournisseur
 * ainsi que la tva pour les entetes
 */
export function *updateDeclarantSaga({ codePostalDeclarant, save }) {
    yield call(updateDeclarantPartenaireSaga, codePostalDeclarant, save);
    if (save) {
        yield call(saveDeclarationSaga);
    }
}

/* Permet de récupérer le taux de tva et le setter pour une entete */
export function *setTvaForEnteteSaga({ index }) {
    const entetes = yield select(selectValidEntetes);
    const entete = entetes[index];
    const roles = yield select(selectUtilisateurRoles);
    const gencodPartenaire = yield select(selectDeclarationPartenaireGencod);
    const gencodDeclarant = yield select(selectDeclarationDeclarantGencod);
    const isFour = checkIsFournisseurConnected(roles);
    if (entete && isNullOrUndefined(entete.tva) && gencodPartenaire && gencodDeclarant) {
        try {
            const response = yield isFour ? call(getTauxTvaForFournisseur, gencodDeclarant, gencodPartenaire) : call(getTauxTvaForBibliotheque, gencodDeclarant);
            yield put(actions.setTvaForEnteteResult(index, response.data));
        } catch (error) {
            yield put(actions.openDialogNoRedirect(ERREUR_GET_TAUX_TVA_FOR_DECLARATION, error.message));
        }
    }
}
