import React, { useEffect } from "react";

import { Trans } from "@lingui/macro";
import { useSelector } from "react-redux";
import { ValidatorForm } from "react-material-ui-form-validator";
import { autoCompleteOnChange, getDateField, getField, inputOnChange, isPaysFrance, switchOnChange, useStyles } from "../../components/monProfil/monProfilUtil";
import { isNotBlank, isUrl } from "../../utils/utility";
import {
    LIBELLE_A_PRECISER,
    LIBELLE_APE,
    LIBELLE_CHAMP_OBLIGATOIRE,
    LIBELLE_COURRIEL,
    LIBELLE_DATE_CREATION,
    LIBELLE_EDI_SSII,
    LIBELLE_ERREUR_DATE_FUTURE,
    LIBELLE_ERREUR_URL_VALIDATION,
    LIBELLE_FORMAT_INCORRECT,
    LIBELLE_FORME_JURIDIQUE,
    LIBELLE_FORME_JURIDIQUE_AUTRE,
    LIBELLE_FORME_JURIDIQUE_CODE_AUTRE,
    LIBELLE_GLN,
    LIBELLE_NOM_COMMERCIAL,
    LIBELLE_NUMERO_SIRET,
    LIBELLE_PRE_ADRESSE,
    LIBELLE_RAISON_SOCIALE_1,
    LIBELLE_SIEGE_SOCIAL,
    LIBELLE_SITE_WEB,
    LIBELLE_TYPE_FORME_JURIDIQUE_FOURNISSEUR,
    LIBELLE_TYPE_FOURNISSEUR,
    LIBELLE_TYPE_FOURNISSEUR_CODE_AUTRE,
    LIBELLE_TYPE_FOURNISSEUR_CODE_INCONNU,
    LIBELLE_TYPE_FOURNISSEUR_CODE_TEST,
    LIBELLE_TYPE_TYPE_FOURNISSEUR
} from "../../utils/libelleConstantes";
import {
    LabelInputTextWithChildren,
    LibelleAutoCompleteSmallOutlined,
    TextValidatorSmallOutlined,
    TextValidatorSmallOutlinedUpperCase
} from "../input/fieldInput";
import AdresseFieldGroupComponent from "../input/adresse/AdresseFieldGroup.component";
import DatePickerValidatorComponent, { isNotDateAfterToday, isValidDate } from "../input/dateValidator/datePickerValidatorComponent";
import { TelephoneFormattedTextInput } from "../input/telephone/TelephoneFormattedTextInput";
import {
    APE,
    COURRIEL_ENTITE,
    DATE_CREATION,
    EDISSII,
    FORME_JURIDIQUE,
    FORME_JURIDIQUE_AUTRE,
    GENCOD,
    MAX_LENGTH_APE,
    MAX_LENGTH_COURRIEL,
    MAX_LENGTH_EDI,
    MAX_LENGTH_FORME_JURIDIQUE_AUTRE,
    MAX_LENGTH_GLN,
    MAX_LENGTH_PRE_ADRESSE,
    MAX_LENGTH_RAISON_SOCIALE1,
    MAX_LENGTH_RAISON_SOCIALE2,
    MAX_LENGTH_SIRET,
    MAX_LENGTH_SITE_WEB,
    MAX_LENGTH_TYPE_AUTRE,
    PRE_ADRESSE,
    RAISON_SOCIALE_1,
    RAISON_SOCIALE_2,
    SIEGE_SOCIAL,
    SIRET,
    SITE,
    TELEPHONE,
    TYPE_ORGANISME,
    TYPE_ORGANISME_AUTRE
} from "../../utils/constantes";
import NumeroSiretInput from "../input/numeroSiret/numeroSiret";
import GreenSwitch from "../switch/GreenSwitch";
import { REQUIRED } from "../../utils/validatorUtil";
import { compareLibellesByCode } from "../../utils/libelleUtil";
import { selectLoginToken } from "../../utils/selectors";
import { isEmptyOrNullOrUndefined } from "../../utils/stringUtil";


export function EntiteFormulaire({ formulaire, setFormulaire, isFournisseur, fromInscription = false }) {
    const classes = useStyles();
    const showTypeAutre = formulaire.typeOrganisme === LIBELLE_TYPE_FOURNISSEUR_CODE_AUTRE;
    const showFormeJuridiqueAutre = formulaire.formeJuridique === LIBELLE_FORME_JURIDIQUE_CODE_AUTRE;
    const isFr = isPaysFrance(formulaire);
    const addCustomFilterTypeFournisseur = l => l.code !== LIBELLE_TYPE_FOURNISSEUR_CODE_INCONNU && l.code !== LIBELLE_TYPE_FOURNISSEUR_CODE_TEST;
    const typeFounisseurTest = formulaire.typeOrganisme === LIBELLE_TYPE_FOURNISSEUR_CODE_TEST;
    const isEtranger = !isFr;

    const isFormeJuridiqueDiffToAutre = isNotBlank(formulaire.formeJuridique) && formulaire.formeJuridique !== LIBELLE_FORME_JURIDIQUE_CODE_AUTRE;
    const isAuthenticated = useSelector(selectLoginToken) !== null;


    // Met à jour le champ modifié dans notre objet de state formulaire
    function onChange(field, value) {
        setFormulaire({
            ...formulaire,
            [field]: value
        });
    }

    // Le simple test sur l'url rend le champ obligatoire, ce que l'on ne veut pas
    ValidatorForm.addValidationRule("matchUrlRegexp", value => isEmptyOrNullOrUndefined(value) || isUrl(value));

    useEffect(() => {
        /*
         * Si l'entité est étrangère alors sa forme juridique est obligatoirement 99 pour "Autre".
         * On vide également les champs qui deviennent cachés.
         */
        if (isEtranger && isFormeJuridiqueDiffToAutre) {
            setFormulaire({
                ...formulaire,
                formeJuridique: LIBELLE_FORME_JURIDIQUE_CODE_AUTRE,
                ediSsii: "",
                ape: "",
                siret: ""
            });
        }
    }, [formulaire, isEtranger, isFormeJuridiqueDiffToAutre]);

    return <div className={classes.alignLeft}>
        {!fromInscription &&
            <LabelInputTextWithChildren labelKey={LIBELLE_GLN} required>
                <TextValidatorSmallOutlined
                    value={getField(formulaire, GENCOD)}
                    disabled={true}
                    inputProps={{ maxLength: MAX_LENGTH_GLN }}
                    validators={[REQUIRED]}
                    errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
            </LabelInputTextWithChildren>
        }
        <LabelInputTextWithChildren labelKey={LIBELLE_RAISON_SOCIALE_1} required>
            <TextValidatorSmallOutlinedUpperCase
                value={getField(formulaire, RAISON_SOCIALE_1)}
                onChange={inputOnChange(RAISON_SOCIALE_1, onChange)}
                inputProps={{ maxLength: MAX_LENGTH_RAISON_SOCIALE1 }}
                validators={[REQUIRED]}
                errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
        </LabelInputTextWithChildren>
        <LabelInputTextWithChildren labelKey={LIBELLE_NOM_COMMERCIAL}>
            <TextValidatorSmallOutlinedUpperCase
                value={getField(formulaire, RAISON_SOCIALE_2)}
                onChange={inputOnChange(RAISON_SOCIALE_2, onChange)}
                inputProps={{ maxLength: MAX_LENGTH_RAISON_SOCIALE2 }}/>
        </LabelInputTextWithChildren>
        {isAuthenticated &&
            <LabelInputTextWithChildren labelKey={LIBELLE_PRE_ADRESSE}>
                <TextValidatorSmallOutlinedUpperCase
                    value={getField(formulaire, PRE_ADRESSE)}
                    onChange={inputOnChange(PRE_ADRESSE, onChange)}
                    inputProps={{ maxLength: MAX_LENGTH_PRE_ADRESSE }}/>
            </LabelInputTextWithChildren>
        }
        <AdresseFieldGroupComponent
            formulaire={formulaire}
            setFormulaire={setFormulaire}
            classes={classes}
            showOnlyPaysFrance={!isFournisseur}/>
        {isFournisseur && <>
            {isFr && <>
                <LabelInputTextWithChildren labelKey={LIBELLE_FORME_JURIDIQUE} required>
                    <LibelleAutoCompleteSmallOutlined id="formeJuridiqueAutoComplete"
                        value={getField(formulaire, FORME_JURIDIQUE)}
                        onChange={autoCompleteOnChange(FORME_JURIDIQUE, onChange)}
                        typeLibelle={LIBELLE_TYPE_FORME_JURIDIQUE_FOURNISSEUR}
                        name="formeJuridique"
                        className={classes.inputFields}
                        validators={[REQUIRED]}
                        errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
                </LabelInputTextWithChildren>
                {showFormeJuridiqueAutre &&
                    <LabelInputTextWithChildren labelKey={LIBELLE_FORME_JURIDIQUE_AUTRE}>
                        <TextValidatorSmallOutlinedUpperCase
                            value={getField(formulaire, FORME_JURIDIQUE_AUTRE)}
                            onChange={inputOnChange(FORME_JURIDIQUE_AUTRE, onChange)}
                            inputProps={{ maxLength: MAX_LENGTH_FORME_JURIDIQUE_AUTRE }}/>
                    </LabelInputTextWithChildren>
                }
                <LabelInputTextWithChildren labelKey={LIBELLE_NUMERO_SIRET} required>
                    <NumeroSiretInput
                        value={getField(formulaire, SIRET)}
                        onChange={inputOnChange(SIRET, onChange)}
                        inputProps={{ maxLength: MAX_LENGTH_SIRET }}
                        codePays={formulaire.codePays}
                        className={classes.inputFields}
                        variant="outlined"
                        size="small"
                        type="text"/>
                </LabelInputTextWithChildren>
                <LabelInputTextWithChildren labelKey={LIBELLE_APE}>
                    <TextValidatorSmallOutlined
                        value={getField(formulaire, APE)}
                        onChange={inputOnChange(APE, onChange)}
                        inputProps={{ maxLength: MAX_LENGTH_APE }}/>
                </LabelInputTextWithChildren>
            </>}
            <LabelInputTextWithChildren labelKey={LIBELLE_DATE_CREATION} required>
                <DatePickerValidatorComponent
                    value={getDateField(formulaire, DATE_CREATION)}
                    onChange={date => onChange(DATE_CREATION, date)}
                    variant="outlined"
                    size="small"
                    disableMinDate
                    validators={[REQUIRED, isNotDateAfterToday, isValidDate]}
                    errorMessages={[
                        <Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>,
                        <Trans id={LIBELLE_ERREUR_DATE_FUTURE}/>,
                        <Trans id={LIBELLE_FORMAT_INCORRECT}/>
                    ]}
                    className={classes.inputFields}/>
            </LabelInputTextWithChildren>
        </>}
        <TelephoneFormattedTextInput
            value={getField(formulaire, TELEPHONE)}
            onChange={v => onChange(TELEPHONE, v)}
            isRequired/>
        <LabelInputTextWithChildren labelKey={LIBELLE_COURRIEL} required>
            <TextValidatorSmallOutlined
                value={getField(formulaire, COURRIEL_ENTITE)}
                onChange={inputOnChange(COURRIEL_ENTITE, onChange)}
                inputProps={{ maxLength: MAX_LENGTH_COURRIEL }}
                validators={[REQUIRED, "isEmail"]}
                errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>, <Trans id={LIBELLE_FORMAT_INCORRECT}/>]}/>
        </LabelInputTextWithChildren>
        <LabelInputTextWithChildren labelKey={LIBELLE_SITE_WEB}>
            <TextValidatorSmallOutlinedUpperCase
                value={getField(formulaire, SITE)}
                onChange={inputOnChange(SITE, onChange)}
                validators={["matchUrlRegexp"]}
                errorMessages={[<Trans id={LIBELLE_ERREUR_URL_VALIDATION}/>]}
                inputProps={{ maxLength: MAX_LENGTH_SITE_WEB }}/>
        </LabelInputTextWithChildren>
        {isFournisseur && <>
            {isFr &&
                <LabelInputTextWithChildren labelKey={LIBELLE_EDI_SSII}>
                    <TextValidatorSmallOutlinedUpperCase
                        value={getField(formulaire, EDISSII)}
                        onChange={inputOnChange(EDISSII, onChange)}
                        inputProps={{ maxLength: MAX_LENGTH_EDI }}/>
                </LabelInputTextWithChildren>
            }
            {!typeFounisseurTest &&
                <>
                    <LabelInputTextWithChildren labelKey={LIBELLE_TYPE_FOURNISSEUR} required>
                        <LibelleAutoCompleteSmallOutlined id="typeFournisseurAutoComplete"
                            value={getField(formulaire, TYPE_ORGANISME)}
                            onChange={autoCompleteOnChange(TYPE_ORGANISME, onChange)}
                            typeLibelle={LIBELLE_TYPE_TYPE_FOURNISSEUR}
                            name="typeFournisseur"
                            customCompare={compareLibellesByCode}
                            addCustomFilter={addCustomFilterTypeFournisseur}
                            className={classes.inputFields}
                            validators={[REQUIRED]}
                            errorMessages={[<Trans id={LIBELLE_CHAMP_OBLIGATOIRE}/>]}/>
                    </LabelInputTextWithChildren>
                </>
            }
            {showTypeAutre &&
                <LabelInputTextWithChildren labelKey={LIBELLE_A_PRECISER}>
                    <TextValidatorSmallOutlinedUpperCase
                        value={getField(formulaire, TYPE_ORGANISME_AUTRE)}
                        onChange={inputOnChange(TYPE_ORGANISME_AUTRE, onChange)}
                        inputProps={{ maxLength: MAX_LENGTH_TYPE_AUTRE }}/>
                </LabelInputTextWithChildren>
            }
            {fromInscription && <LabelInputTextWithChildren labelKey={LIBELLE_SIEGE_SOCIAL}>
                <div className={classes.inputFields}>
                    <GreenSwitch
                        checked={formulaire.siegeSocial}
                        onChange={switchOnChange(SIEGE_SOCIAL, onChange)}
                    />
                </div>
            </LabelInputTextWithChildren>}
        </>}
    </div>;
}
