import axios from "axios/index";

const GET_PARTENAIRES_FAVORIS = "/mesPartenaires/getListPartenairesFavoris";
const GET_PARTENAIRES_RECENTS = "/mesPartenaires/getListPartenairesRecents";
const ADD_PARTENAIRES_TO_FAVORIS_IF_NOT_EXISTS = "/mesPartenaires/addListPartenaireFavorisIfNotExists";
const REMOVE_PARTENAIRE_FROM_FAVORIS = "/mesPartenaires/deletePartenaireFavoris";
const EXPORTER_PARTENAIRES = "/mesPartenaires/exporterListeMesPartenaires";
const GET_PARTENAIRES_FAVORIS_FOR_SAISIEWEB = "/mesPartenaires/getPartenairesFavorisForSaisieWeb";
const GET_MES_LISTES_PARTENAIRES = "/mesPartenaires/getListesPartenaires";
const CHECK_IF_PARTENAIRE_RECENT = "/mesPartenaires/checkIfPartenaireRecent";

export const getPartenairesFavorisService = (gencodDeclarant, filtre) => axios.post(GET_PARTENAIRES_FAVORIS, {
    gencodDeclarant,
    ...filtre
});

export const getPartenairesRecentsService = (gencodDeclarant, filtre) => axios.post(GET_PARTENAIRES_RECENTS, {
    gencodDeclarant,
    ...filtre
});

export const addPartenaireFavorisIfNotExistsService = data => axios.post(ADD_PARTENAIRES_TO_FAVORIS_IF_NOT_EXISTS, data);

export const removePartenaireFavorisService = (gencodDeclarant, gencodFavoris) => {
    const formData = new FormData();
    formData.append("glnDeclarant", gencodDeclarant);
    formData.append("glnFavori", gencodFavoris);
    return axios.post(REMOVE_PARTENAIRE_FROM_FAVORIS, formData);
};

export const exporterPartenairesService = gencodDeclarant => {
    const requestConfig = {
        params: { gencod: gencodDeclarant },
        responseType: "blob"
    };
    return axios.get(EXPORTER_PARTENAIRES, requestConfig);
};


export const getFavorisForSaisieWeb = gencodDeclarant => {
    const requestConfig = {
        params: { gencod: gencodDeclarant }
    };
    return axios.get(GET_PARTENAIRES_FAVORIS_FOR_SAISIEWEB, requestConfig);
};

export const getMesListesPartenairesService = (gencodDeclarant, filtreFavoris, filtreRecents) => axios.post(GET_MES_LISTES_PARTENAIRES, { filtreFavoris: { gencodDeclarant, ...filtreFavoris },
    filtreRecents: { gencodDeclarant, ...filtreRecents } });

export const checkIfPartenaireRecentService = (gencodDeclarant, glnFavori) => {
    const formData = new FormData();
    formData.append("glnDeclarant", gencodDeclarant);
    formData.append("glnFavori", glnFavori);
    return axios.post(CHECK_IF_PARTENAIRE_RECENT, formData);
};
