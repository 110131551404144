import { call, put, select } from "redux-saga/effects";
import {
    isValidSirenOrSiret,
    updateLocaleForUser,
    updatePreferenceSizePagination
} from "../../../services/utilisateur.service";
import { getMenuBibliotheque, getMenuFourni, MENU_ADMIN } from "../../../utils/menuUtil";

import * as actions from "../../actions/index.action";
import { getInformationsDeclarantByGencod } from "../../../services/declarant.service";
import { selectLoginToken, selectUtilisateurGencod } from "../../../utils/selectors";
import {
    ROLES
} from "../../../utils/constantes";
import { checkIsFournisseurConnected } from "../../../utils/utility";
import { getFavorisForSaisieWeb } from "../../../services/mesPartenaires.service";
import {
    ERREUR_CHECK_IF_FOUR_CANMAKE_GLOBAL_DECLARATION,
    ERREUR_CHECK_SIRET,
    ERREUR_GET_PARTENARES_FAVORIS,
    ERREUR_RECHERCHE_INFORMATIONS
} from "../../../utils/libelleConstantes";


export const getMenuFromProfil = (roles, regroupe) => {
    if (roles.find(role => role.type === ROLES.ADMIN)) {
        return MENU_ADMIN;
    } else if (roles.find(role => role.type === ROLES.BIBLIOTHEQUE)) {
        return getMenuBibliotheque(!regroupe);
    } else if (roles.find(role => role.type === ROLES.FOURNISSEUR)) {
        return getMenuFourni(!regroupe);
    }
    return [];
};

export function *updateInformationsDeclarant(serviceResponse) {
    const datas = serviceResponse.data;
    const { roles, regroupements, ...informations } = datas;
    // Sauvegarde des infos du déclarant dans le store utilisateur.informations
    yield put(actions.getInformationsDeclarantSuccess(informations, roles, regroupements));

    // Mettre à jour le store monProfil
    const { stepPremiereConnexion, rdpDuplique, steps } = informations.premiereConnexion;
    yield put(actions.updateMonProfilPremiereConnexionInfos(stepPremiereConnexion, steps, rdpDuplique, checkIsFournisseurConnected(roles)));
    yield put(actions.getMonProfil(informations.gencod));

    // Met à jour le menu
    const menuFromProfil = getMenuFromProfil(roles, informations.regroupe);
    yield put(actions.majMenuItems(menuFromProfil));
}

export function *getInformationsDeclarantSaga({ gencod }) {
    yield put(actions.updateUtilisateurLoading(true));
    try {
        const response = yield call(getInformationsDeclarantByGencod, gencod);
        yield call(updateInformationsDeclarant, response);
    } catch (error) {
        if (error.response) {
            yield put(actions.openDialogNoRedirect(error.response.data.error, error.response.data.message));
        } else {
            yield put(actions.openDialogNoRedirect(ERREUR_RECHERCHE_INFORMATIONS, error.message));
        }
    }
    yield put(actions.updateUtilisateurLoading(false));
}

export function *updatePreferenceSizePaginationSaga(action) {
    const isConnected = yield select(selectLoginToken);
    const { gencod, preferenceSizePagination } = action;
    if (isConnected) {
        try {
            yield updatePreferenceSizePagination(gencod, preferenceSizePagination);
        } catch (error) {
            yield put(actions.openDialogNoRedirect(ERREUR_CHECK_IF_FOUR_CANMAKE_GLOBAL_DECLARATION, error.message));
        }
    }
    yield put(actions.updatePreferenceSizePaginationSuccess(gencod, preferenceSizePagination));
}

export function *updateLocaleSaga(action) {
    const isConnected = yield select(selectLoginToken);
    const { gencod, locale } = action;
    if (isConnected) {
        try {
            yield updateLocaleForUser(gencod, locale);
        } catch (error) {
            yield put(actions.openDialogNoRedirect(ERREUR_CHECK_IF_FOUR_CANMAKE_GLOBAL_DECLARATION, error.message));
        }
    }
}

export function *checkSiretSaga(action) {
    yield put(actions.checkSiretSuccess(true));
    try {
        const response = yield call(isValidSirenOrSiret, action.siret);
        yield put(actions.checkSiretSuccess(response.data));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_CHECK_SIRET, error.message));
    }
}

export function *getPartenairesFavorisForSaisieWebSaga() {
    const gencod = yield select(selectUtilisateurGencod);
    try {
        const response = yield call(getFavorisForSaisieWeb, gencod);
        yield put(actions.updateFavorisListSuccess(response.data));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_GET_PARTENARES_FAVORIS, error.message));
    }
}
