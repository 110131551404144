import { call, delay, put, select } from "redux-saga/effects";
import {
    DEFAULT_SIZE_PER_PAGE,
    DOUTEUSE,
    ENVOYEE,
    FACTUREE,
    MES_DECLARATIONS_DOUTEUSE,
    MES_DECLARATIONS_EN_ATTENTE,
    MES_DECLARATIONS_ENVOYEE,
    MES_DECLARATIONS_ENVOYEE_BIB,
    ROLES,
    SAUVEGARDE,
    SORT_NULL
} from "../../../utils/constantes";
import * as actions from "../../actions/index.action";
import { checkIsBibliothequeConnected, checkProfilConnected, getComparator, hasDoute, stableSort } from "../../../utils/utility";
import {
    getEntetesBibliothequeByFiltreAndPage,
    getEntetesBibliothequeWithNombresAndStatsByFiltre,
    getEntetesBibliothequeWithNombresAndStatsWithoutPartenaireByFiltre,
    getEntetesFournisseurByFiltreAndPage,
    getEntetesFournisseurWithNombresAndStatsByFiltre,
    getEntetesFournisseurWithNombresAndStatsWithoutPartenaireByFiltre,
    getLitsDeclarationValidLignesByNumeroDeclaration,
    getNombreDeclarationsParTypeBibliotheque,
    getNombreDeclarationsParTypeFournisseur,
    getStatistiqueMontantsMDDtoByFiltre,
    getStatistiquePartenaireDtoBibliothequeByFiltre,
    getStatistiquePartenaireDtoFournisseurByFiltre,
    invalidateDeclarationBibliothequeByNumeroDeclaration,
    invalidateDeclarationFournisseurByNumeroDeclaration,
    modifyDeclarationFournisseurFacturee,
    rollBackDeclarationBibliothequeByNumeroDeclaration,
    rollBackDeclarationFournisseurByNumeroDeclaration,
    sendAllDeclarationsBibliotheque,
    sendAllDeclarationsFournisseur,
    sendDeclarationBibliothequeByNumeroDeclaration,
    sendDeclarationFournisseurByNumeroDeclaration
} from "../../../services/mesDeclarations.service";
import { selectMesDeclarationsEntetes, selectState, selectUtilisateurRoles } from "../../../utils/selectors";
import { globalI18n } from "../../../i18n.conf";

import { arrayNotEmpty } from "../../../utils/objectUtil";
import {
    ERREUR_ANNULER_DECLARATION,
    ERREUR_ENVOYER_DECLARATION,
    ERREUR_INTEGRATION_DECLARATIONLIGNE_BY_NUMERODECLARATION,
    ERREUR_MES_DECLARATIONS,
    ERREUR_MES_DECLARATIONS_SEND_ALL,
    ERREUR_MODIFIER_DECLARATION_FACTUREE,
    ERREUR_NOMBRE_DECLARATION_PAR_TYPE,
    ERREUR_NOMBRE_DECLARATION_TYPE
} from "../../../utils/libelleConstantes";

export const getTotalDeclarationByTypeFromResponse = (type, mapNombreDeclarationsParTypeFromResponse) => {
    const keyValueType = {
        D: "nbDouteuses",
        S: "nbEnAttenteEnvoi",
        E: "nbEnvoyees",
        F: "nbFacturees"
    };
    const keyFinal = keyValueType[type];
    return mapNombreDeclarationsParTypeFromResponse[keyFinal];
};

export const transformResponse = (response, withStatsPartenaire) => ({
    nombreDeclarationParType: {
        envoyee: response.nbDeclarationsParType.nbEnvoyees,
        facturee: response.nbDeclarationsParType.nbFacturees,
        douteuse: response.nbDeclarationsParType.nbDouteuses,
        enAttente: response.nbDeclarationsParType.nbEnAttenteEnvoi
    },
    ...(withStatsPartenaire && { filtresPartenaires: response.nbDeclarationsParType.partenaires }),
    filtresSource: response.nbDeclarationsParType.sources,
    entetes: response.entetes,
    nombreDoutesParType: response.doutes && response.doutes.doutesMap ? response.doutes.doutesMap : [],
    nombreTotalDoutes: response.doutes && response.doutes.nombreTotalDoutes ? response.doutes.nombreTotalDoutes : 0
});


const updateAllInfo = (response, typeDeclaration, withStatsPartenaire) => put(actions.updateMdAllInfos({
    ...transformResponse(response, withStatsPartenaire),
    totalSizeRecap: getTotalDeclarationByTypeFromResponse(typeDeclaration, response.nbDeclarationsParType)
}));

const getFilterData = filtres => {
    const glnPartenaires = filtres.glnPartenaires.map(item => item.gln);
    const sourcesDeclaration = filtres.sourcesDeclaration.length === filtres.filtresSource.length ? [] : filtres.sourcesDeclaration.map(item => item.libelle.code);
    const douteList = filtres.doutes.length === filtres.nombreDoutesParType.length ? [] : filtres.doutes.map(item => item.libelle);
    return {
        typeDeclaration: filtres.typeDeclaration,
        glnDeclarant: filtres.glnDeclarant,
        glnPartenaires,
        anneeDebut: filtres.anneeDebut,
        anneeFin: filtres.anneeFin,
        typeAnnee: filtres.typeAnnee,
        onlyInvalidDeclaration: filtres.onlyInvalidDeclaration,
        sourcesDeclaration,
        douteList
    };
};

const triDateParTypeDeclaration = (type, tri) => {
    // Douteuse, en attente -> date modification donc on retire date d'envoi
    if (type === SAUVEGARDE || type === DOUTEUSE) {
        return tri.column !== "dateEnvoiSofia" && tri.column !== "dateDeFacture";
    }
    // Envoye, facture -> date d'envoi donc on retire date modif
    if (type === ENVOYEE) {
        return tri.column !== "dateModification" && tri.column !== "dateDeFacture";
    }
    if (type === FACTUREE) {
        return tri.column !== "dateModification" && tri.column !== "dateEnvoiSofia";
    }
    return false;
};

const getFilterAndPaginationData = (filtres, preferenceSizePage) => ({
    ...getFilterData(filtres),
    sortColumns: filtres.sort
        .filter(tri => tri.direction !== SORT_NULL)
        .filter(tri => triDateParTypeDeclaration(filtres.typeDeclaration, tri)),
    size: preferenceSizePage > 0 ? preferenceSizePage : DEFAULT_SIZE_PER_PAGE,
    page: filtres.page - 1
});

export function *getEntetes(updateAction) {
    const state = yield select(selectState);
    const profil = checkProfilConnected(state.utilisateur.roles);
    const filtres = state.mesDeclarations;
    const preferenceSizePage = state.utilisateur.informations.preferenceSizePagination;
    let response;
    yield put(actions.setMdLoading(true));
    try {
        const data = getFilterAndPaginationData(filtres, preferenceSizePage);
        if (profil === ROLES.BIBLIOTHEQUE) {
            response = yield call(getEntetesBibliothequeByFiltreAndPage, data);
        } else {
            response = yield call(getEntetesFournisseurByFiltreAndPage, data);
        }
        yield put(updateAction(response.data.data.entetes));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_MES_DECLARATIONS, error.cause || error.message));
        yield put(actions.setMdLoading(false));
    }
}

export function *updateEntetesSaga() {
    yield call(getEntetes, actions.updateMdResultat);
}

export function *updateEntetesAndRowIndexSaga(action) {
    const actionFunction = entetes => actions.updateEntetesAndRowIndexResult(entetes, action.index);
    yield call(getEntetes, actionFunction);
}

function getFiltre(stateMesDeclarations, preferenceSizePage) {
    let filtreData;
    if (stateMesDeclarations.typeDeclaration !== null) {
        filtreData = getFilterAndPaginationData(stateMesDeclarations, preferenceSizePage);
    } else {
        filtreData = getFilterData(stateMesDeclarations);
    }
    return filtreData;
}

export function *getPartenairesAndUpdateFiltreAndSelection() {
    const state = yield select(selectState);
    const partenairesSelectionnes = state.mesDeclarations.glnPartenaires;
    const mesDeclarations = state.mesDeclarations;
    const { informations, roles } = state.utilisateur;
    const preferenceSizePage = informations.preferenceSizePagination;
    const profil = checkProfilConnected(roles);
    const serviceRecherchePartenaire = (profil === ROLES.BIBLIOTHEQUE)
        ? getStatistiquePartenaireDtoBibliothequeByFiltre : getStatistiquePartenaireDtoFournisseurByFiltre;
    const filtreData = getFiltre(mesDeclarations, preferenceSizePage);
    const responseRecherchePartenaire = yield call(serviceRecherchePartenaire, filtreData);

    // Mettre à jour des partenaires proposés dans le filtre (utile lors d'un changement de zone par exemple)
    yield put(actions.updateMdFiltrePartenaires(responseRecherchePartenaire.data));

    // Mise à jour du nombre de déclarations des partenaires sélectionnés (même si la sélection est inchangée, il faut mettre à jour les nb de déclarations)
    const arrayGencodPartenaireSelectionne = partenairesSelectionnes.map(p => p.gln);
    const arrayNewPartenaireSelectionne = responseRecherchePartenaire.data.filter(newPartenaire => arrayGencodPartenaireSelectionne.includes(newPartenaire.gln));

    // Dans le cas ou les partenaires sélectionnés ne seraient pas retrouvés dans la nouvelle liste des partenaire, on les ajoute manuellement avec un count à 0
    partenairesSelectionnes.forEach(partenaireSelectionne => {
        if (!arrayNewPartenaireSelectionne.find(newPartenaire => newPartenaire.gln === partenaireSelectionne.gln)) {
            arrayNewPartenaireSelectionne.push({
                gln: partenaireSelectionne.gln,
                raisonSociale: partenaireSelectionne.raisonSociale,
                ville: partenaireSelectionne.ville,
                count: 0
            });
        }
    });
    yield put(actions.updateMdPartenaires(arrayNewPartenaireSelectionne));
}

export function *getInfosNecessaires() {
    yield delay(500); // S'il y a plusieurs choix rapides de partenaires ou de sources, le "takelast" de la saga ne fera qu'un seul appel
    yield put(actions.setMdLoading(true));
    const state = yield select(selectState);
    const { informations, roles } = state.utilisateur;
    const profil = checkProfilConnected(roles);
    const mesDeclarations = state.mesDeclarations;
    const preferenceSizePage = informations.preferenceSizePagination;

    const partenairesSelectionnes = state.mesDeclarations.glnPartenaires;
    // Si des partenaires sont sélectionnés dans le filtre, alors la mise à jour des partenaires est dissociée de la recherche des déclarations
    const isRecherchePartenaireDissociee = partenairesSelectionnes.length > 0;

    let response;
    try {
        let service;
        const filtreData = getFiltre(mesDeclarations, preferenceSizePage);
        if (mesDeclarations.typeDeclaration !== null) {
            if (isRecherchePartenaireDissociee) {
                // Dans le cas d'une recherche des partenaires dissociée, on appelle un service qui ne récupère pas les partenaires
                service = (profil === ROLES.BIBLIOTHEQUE)
                    ? getEntetesBibliothequeWithNombresAndStatsWithoutPartenaireByFiltre : getEntetesFournisseurWithNombresAndStatsWithoutPartenaireByFiltre;
            } else {
                service = (profil === ROLES.BIBLIOTHEQUE)
                    ? getEntetesBibliothequeWithNombresAndStatsByFiltre : getEntetesFournisseurWithNombresAndStatsByFiltre;
            }
        } else {
            service = (profil === ROLES.BIBLIOTHEQUE) ? getNombreDeclarationsParTypeBibliotheque : getNombreDeclarationsParTypeFournisseur;
        }
        response = yield call(service, filtreData);

        // Si recherche partenaire dissociée, la mise à jour des partenaires ne se fait plus ici
        yield updateAllInfo(response.data, mesDeclarations.typeDeclaration, !isRecherchePartenaireDissociee);
        yield put(actions.setMdLoading(false));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_NOMBRE_DECLARATION_TYPE, error.cause || error.message));
        yield put(actions.setMdLoading(false));
    }
}

export function *getInfosNecessairesAndPartenaireAndUpdateFiltre() {
    yield call(getInfosNecessaires);
    yield call(getPartenairesAndUpdateFiltreAndSelection);
}

export function *getMDListDeclarationsLigneByNumeroDeclarationSaga(action) {
    try {
        yield put(actions.setMdLoading(true));
        const data = yield call(getLitsDeclarationValidLignesByNumeroDeclaration, action.numeroDeclaration);
        yield put(actions.getMDListeDelcarationsLigneByNumeroDeclarationResult(action.index, stableSort(Object.values(data.data), getComparator("desc", "dateModification"))));
        yield put(actions.setMdLoading(false));
    } catch (error) {
        yield put(actions.setMdLoading(false));
        yield put(actions.openDialogNoRedirect(ERREUR_INTEGRATION_DECLARATIONLIGNE_BY_NUMERODECLARATION, error.message));
    }
}

export function *sendMdAllDeclarationsFiltrees() {
    const state = yield select(selectState);
    const { informations, roles } = state.utilisateur;
    const profil = checkProfilConnected(roles);
    try {
        const service = (profil === ROLES.BIBLIOTHEQUE) ? sendAllDeclarationsBibliotheque : sendAllDeclarationsFournisseur;
        const response = yield call(
            service,
            getFilterAndPaginationData(state.mesDeclarations, informations.preferenceSizePagination)
        );
        yield updateAllInfo(response.data, state.mesDeclarations.typeDeclaration);
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_MES_DECLARATIONS_SEND_ALL, error.message));
    }
}

export function *rollBackDeclarationModificationByNumeroDeclarationSaga(action) {
    const state = yield select(selectState);
    const { rowIndex, entetes } = state.mesDeclarations;
    const { informations, roles } = state.utilisateur;
    const profil = checkProfilConnected(roles);
    const filtre = getFilterAndPaginationData(state.mesDeclarations, informations.preferenceSizePagination);
    try {
        let response;
        if (profil === ROLES.FOURNISSEUR) {
            response = yield call(rollBackDeclarationFournisseurByNumeroDeclaration, action.numeroDeclaration, filtre);
        } else {
            response = yield call(rollBackDeclarationBibliothequeByNumeroDeclaration, action.numeroDeclaration, filtre);
        }
        // Dispatch de l'action de mise à jour du store
        yield updateAllInfo(response.data, filtre.typeDeclaration);

        // Si la declaration n'est pas seule
        if (entetes && entetes.length && entetes.length > 1) {
            // Et qu'elle est la dernière, on passe à la précédente
            if (action.isLastDeclaration) {
                yield put(actions.updateIndexOpenedDeclaration(rowIndex - 1));
            }
        } else {
            // Si elle est seule, on referme la popup
            yield put(actions.closeDetailDialogFromMesDeclarations());
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_ANNULER_DECLARATION, error.message));
    }
}

export function *invalidateDeclarationByNumeroDeclarationSaga(action) {
    const state = yield select(selectState);
    const rowIndex = state.mesDeclarations.rowIndex;
    const { informations, roles } = state.utilisateur;
    const profil = checkProfilConnected(roles);
    const filtre = getFilterAndPaginationData(state.mesDeclarations, informations.preferenceSizePagination);
    try {
        let response;
        if (profil === ROLES.FOURNISSEUR) {
            response = yield call(invalidateDeclarationFournisseurByNumeroDeclaration, action.numeroDeclaration, filtre);
        } else {
            response = yield call(invalidateDeclarationBibliothequeByNumeroDeclaration, action.numeroDeclaration, filtre);
        }
        // Dispatch de l'action de mise à jour du store
        yield updateAllInfo(response.data, filtre.typeDeclaration);
        if (action.isLastDeclaration) {
            yield put(actions.updateIndexOpenedDeclaration(rowIndex - 1));
        }
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_ANNULER_DECLARATION, error.message));
    }
}


export function *waitDuringUpdateDeclaration() {
    let loading = yield select(storre => storre.declaration.loading);
    while (loading) {
        yield delay(500); // On attend un peu que la declaration finisse de se mettre à jour
        loading = yield select(storre => storre.declaration.loading);
    }
}


export function *checkStatutMDDeclarationSaga() {
    yield call(waitDuringUpdateDeclaration);
    const state = yield select(selectState);
    const declaration = state.declaration.declaration;
    const { typeDeclaration, showClone } = state.mesDeclarations;
    let hasListChanged = false;
    if (declaration && declaration.validEntetes && declaration.validEntetes.length > 0) {
        const isEnCours = declaration.validEntetes[0].statut.cellValue === SAUVEGARDE;
        const isEnvoyee = declaration.validEntetes[0].statut.cellValue === ENVOYEE;
        const douteuse = hasDoute(declaration);
        if (showClone) {
            let paramKeyMessage;
            if (isEnvoyee) {
                const isBib = checkIsBibliothequeConnected(selectUtilisateurRoles(state));
                paramKeyMessage = isBib ? MES_DECLARATIONS_ENVOYEE_BIB : MES_DECLARATIONS_ENVOYEE;
            } else if (isEnCours) {
                paramKeyMessage = douteuse ? MES_DECLARATIONS_DOUTEUSE : MES_DECLARATIONS_EN_ATTENTE;
            }
            if (paramKeyMessage) {
                yield put(actions.updateFlagShowAndMessageSnackbar(true, "mesDeclarationsSnackbarRubriqueCloneLabel", { tileLabel: globalI18n._(paramKeyMessage) }));
            }
        } else {
            if (douteuse && typeDeclaration !== DOUTEUSE) {
                yield put(actions.updateFlagShowAndMessageSnackbar(true, "mesDeclarationsSnackbarRubriqueDeclarationLabel", { tileLabel: globalI18n._(MES_DECLARATIONS_DOUTEUSE) }));
                hasListChanged = true;
            } else if ((!douteuse && typeDeclaration === DOUTEUSE) || (isEnCours && typeDeclaration === ENVOYEE)) {
                yield put(actions.updateFlagShowAndMessageSnackbar(true, "mesDeclarationsSnackbarRubriqueDeclarationLabel", { tileLabel: globalI18n._(MES_DECLARATIONS_EN_ATTENTE) }));
                hasListChanged = true;
            }
            if (hasListChanged) {
                yield put(actions.getInfosNecessairesSaga());
            }
        }
    }
    return hasListChanged;
}

const findRowIndex = (entete, isClone, isOriginale) => e => {
    let numeroDeclaration;
    if (isClone) {
        numeroDeclaration = e.clone ? e.clone.entete.numeroDeclaration.cellValue : null;
    } else if (isOriginale) {
        numeroDeclaration = e.originale ? e.originale.entete.numeroDeclaration.cellValue : null;
    } else {
        numeroDeclaration = e.entete.numeroDeclaration.cellValue;
    }
    return numeroDeclaration === entete.numeroDeclaration.cellValue;
};

const isEnteteChange = (enteteBefore, enteteAfter) => enteteBefore.statut.cellValue === SAUVEGARDE && enteteBefore.dateModification.cellValue !== enteteAfter.dateModification.cellValue;


export function *closeMDDeclarationSaga(action) {
    const declaration = yield select(state => state.declaration.declaration);
    const partenaire = yield select(state => state.declaration.partenaire);
    const typeDeclaration = yield select(state => state.mesDeclarations.typeDeclaration);
    const showClone = yield select(state => state.mesDeclarations.showClone);
    const showOriginale = yield select(state => state.mesDeclarations.showOriginale);
    const mesDeclarationsEntetes = yield select(selectMesDeclarationsEntetes);
    const entete = declaration.validEntetes[0];
    const rowIndex = mesDeclarationsEntetes.findIndex(findRowIndex(entete, showClone, showOriginale));
    const lignes = declaration.validLignes;
    // On vérifie qu'il y a des déclarations avant de mettre à jour le store en plus, il faut vérifier que les déclarations ont bien changé
    if (arrayNotEmpty(mesDeclarationsEntetes) && isEnteteChange(entete, mesDeclarationsEntetes[rowIndex].entete)) {
        yield put(actions.updateMDDeclarationEnteteEtLignes(entete, lignes, partenaire, showClone, showOriginale, rowIndex));
    }

    const readOnly = action.closingPopup ? false : typeDeclaration === FACTUREE;
    yield put(actions.updateDeclarationReadOnly(readOnly));
    yield put(actions.updateIndexOpenedDeclaration(-1));
    yield put(actions.reinitDeclarationTime());
}

export function *modifyDeclarationFactureeSaga(action) {
    const state = yield select(selectState);
    const profil = checkProfilConnected(state.utilisateur.roles);
    yield put(actions.setDeclarationLoading(true));
    try {
        let response;
        if (profil === ROLES.FOURNISSEUR) {
            response = yield call(modifyDeclarationFournisseurFacturee, action.numeroDeclaration);
        }
        // Dispatch de l'action de mise à jour du store
        yield put(actions.updateDeclarationEntetes([response.data.data.wrapperDto.clone.entete]));
        yield put(actions.addClone(response.data.data.wrapperDto.clone));
        yield put(actions.updateMDShowClone(true));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_MODIFIER_DECLARATION_FACTUREE, error.message));
    }
    yield put(actions.setDeclarationLoading(false));
}

export function *waitForDialogModifToBeSaved() {
    let dialogDeclarationModifEnCours = yield select(state => state.mesDeclarations.dialogDeclarationModifEnCours);
    // En attente si la déclaration a été modifiée et pas encore reçue par le back
    while (dialogDeclarationModifEnCours) {
        // Attend 50 ms avant de revérifier si la déclaration a été reçue par le back
        yield delay(50);
        dialogDeclarationModifEnCours = yield select(state => state.mesDeclarations.dialogDeclarationModifEnCours);
    }
}

export function *onPreviousDeclarationFromMesDeclarationsSaga() {
    yield call(waitForDialogModifToBeSaved);
    let newRowIndex;
    const state = yield select(selectState);
    const rowIndex = state.mesDeclarations.rowIndex;
    const page = state.mesDeclarations.page;
    const preferenceSizePagination = state.utilisateur.informations.preferenceSizePagination;
    yield call(checkStatutMDDeclarationSaga);
    if (rowIndex === 0) {
        yield put(actions.updateMdPage(page - 1));
        newRowIndex = preferenceSizePagination - 1;
        yield put(actions.updateEntetesAndRowIndex(newRowIndex));
    } else {
        yield call(closeMDDeclarationSaga, actions.closeMDDeclaration(false));
        yield put(actions.updateMDShowClone(false));
        newRowIndex = rowIndex - 1;
        yield put(actions.updateIndexOpenedDeclaration(newRowIndex));
    }
}

export function *onNextDeclarationFromMesDeclarationsSaga() {
    yield call(waitForDialogModifToBeSaved);
    const state = yield select(selectState);
    let newRowIndex;
    const { rowIndex, page } = state.mesDeclarations;
    const preferenceSizePagination = state.utilisateur.informations.preferenceSizePagination;
    const hasListChanged = yield call(checkStatutMDDeclarationSaga);
    if (!hasListChanged && rowIndex === preferenceSizePagination - 1) {
        yield put(actions.updateMdPage(page + 1));
        yield put(actions.updateEntetesAndRowIndex(0));
    } else {
        yield call(closeMDDeclarationSaga, actions.closeMDDeclaration(false));
        yield put(actions.updateMDShowClone(false));
        newRowIndex = hasListChanged ? rowIndex : rowIndex + 1;
        yield put(actions.updateIndexOpenedDeclaration(newRowIndex));
    }
}

export function *closeDetailDialogMesDeclarationsSaga() {
    yield call(waitForDialogModifToBeSaved);
    yield call(checkStatutMDDeclarationSaga);
    yield call(closeMDDeclarationSaga, actions.closeMDDeclaration(true));
    yield put(actions.updateMDOpenDetailDialog(false));
    yield put(actions.updateMDShowClone(false));
    yield put(actions.updateMDShowOriginale(false));
}

export function *sendDeclarationByNumeroDeclarationSaga(action) {
    const state = yield select(selectState);
    const { roles, informations } = state.utilisateur;
    const profil = checkProfilConnected(roles);
    const preferenceSizePage = informations.preferenceSizePagination;
    const { rowIndex, entetes } = state.mesDeclarations;
    const filtre = getFilterAndPaginationData(state.mesDeclarations, preferenceSizePage);
    try {
        const service = (profil === ROLES.FOURNISSEUR)
            ? sendDeclarationFournisseurByNumeroDeclaration
            : sendDeclarationBibliothequeByNumeroDeclaration;
        const response = yield call(service, action.numeroDeclaration, filtre);
        // Dispatch de l'action de mise à jour du store
        yield updateAllInfo(response.data, filtre.typeDeclaration);
        // Affichage du snackbar pour informer du déplacement de la déclaration dans la rubrique ENVOYEE
        yield put(actions.updateFlagShowAndMessageSnackbar(
            true,
            "mesDeclarationsSnackbarRubriqueDeclarationLabel",
            { tileLabel: globalI18n._(MES_DECLARATIONS_ENVOYEE) }
        ));
        // Si la declaration n'est pas seule
        if (entetes && entetes.length && entetes.length > 1) {
            // Et qu'elle est la dernière, on passe à la précédente
            if (action.isLastDeclaration) {
                yield put(actions.updateIndexOpenedDeclaration(rowIndex - 1));
            }
        } else {
            // Si elle est seule, on referme la popup
            yield call(closeDetailDialogMesDeclarationsSaga);
        }
        yield put(actions.checkIfEcartIsPresent(informations.gencod));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_ENVOYER_DECLARATION, error.message));
    }
}

export function *showNextMesDeclarations() {
    const state = yield select(selectState);
    const { page, rowIndex } = state.mesDeclarations;
    const preferenceSizePagination = state.utilisateur.informations.preferenceSizePagination;
    const totalSize = state.mesDeclarations.totalSizeRecap;

    const rowIndexOnLastDeclaration = rowIndex + ((page - 1) * preferenceSizePagination) === totalSize - 1;

    if (rowIndexOnLastDeclaration) {
        yield call(closeDetailDialogMesDeclarationsSaga, actions.closeMDDeclaration());
    } else {
        yield call(checkStatutMDDeclarationSaga);
    }
    yield put(actions.setDeclarationLoading(false));
}


export function *updateStatistiquesMontantsMesDeclarationsSaga() {
    const state = yield select(selectState);
    const { informations } = state.utilisateur;
    const mesDeclarations = state.mesDeclarations;
    const preferenceSizePage = informations.preferenceSizePagination;
    yield put(actions.setMdLoadingStatistiquesMontants(true));
    try {
        const filtreData = getFiltre(mesDeclarations, preferenceSizePage);
        const response = yield call(getStatistiqueMontantsMDDtoByFiltre, filtreData);
        yield put(actions.updateMDStatistiquesMontantsResult(response.data));
    } catch (error) {
        yield put(actions.openDialogNoRedirect(ERREUR_NOMBRE_DECLARATION_PAR_TYPE, error.cause || error.message));
        yield put(actions.setMdLoadingStatistiquesMontants(false));
    }
}

export function *reinitMdTypeDeclarationSaga({ value }) {
    yield put(actions.updateMdPage(1));
    yield put(actions.updateMdResultat([]));

    yield put(actions.updateMdTypeDeclaration(value));
    yield call(getInfosNecessaires);
}

export function *updateMdFiltreAnneeSaga({ name }) {
    yield put(actions.updateMdPage(1));
    yield put(actions.updateMdTypeAnnee(name));
    yield put(actions.getInfosNecessairesSaga());
    yield put(actions.getPartenaireAndUpdateFiltre());
}

